:root {
  /*Base colors*/
  --color-white: #ffffff;
  --color-black: #111111;
  --color-background: #f7fbfc;

  /*Primary colors*/
  --color-primary: #9fb8eb;
  --color-primary-light: #f2f6ff;
  --color-primary-dark: #4f79d0;

  /*Accent colors*/
  --color-accent: #fec50c;
  --color-accent-100: #fff9e7;
  --color-accent-200: #fff3ce;
  --color-accent-300: #ffe89e;
  --color-accent-400: #fed13d;
  --color-accent-500: var(--color-accent);
  --color-accent-600: #cb9e0a;
  --color-accent-700: #cb9e0a;
  --color-accent-800: #664f05;
  --color-accent-900: #332702;
  --color-accent-dark: var(--color-accent-500);
  --color-accent-light: var(--color-accent-100);

  /*Neutral colors*/
  --color-neutral: #59606a;
  --color-neutral-100: #f0f4f7;
  --color-neutral-200: #e1e8ef;
  --color-neutral-300: #c0c8d1;
  --color-neutral-400: #939aa4;
  --color-neutral-500: var(--color-neutral);
  --color-neutral-600: #474d55;
  --color-neutral-700: #353a40;
  --color-neutral-800: #24262a;
  --color-neutral-900: #1b1d20;
  --color-neutral-dark: var(--color-neutral-500);
  --color-neutral-light: var(--color-neutral-100);

  /*Danger colors*/
  --color-danger: #f54848;
  --color-danger-light: #ffe8e8;
  --color-danger-dark: #af0808;

  /*Success colors*/
  --color-success: #52cb50;
  --color-success-light: #e2ffd7;
  --color-success-dark: #146f29;

  /*Warning colors*/
  --color-warning: #f3c10e;
  --color-warning-light: #fff5c4;
  --color-warning-dark: #a47908;

  /*Information colors*/
  --color-information: #3aa1ec;
  --color-information-light: #def1ff;
  --color-information-dark: #006ebe;

  /*Status colors*/
  --color-status-1: #f2ffee;
  --color-status-2: #c8ff66;
  --color-status-3: #ff573f;
}

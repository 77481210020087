@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(//fonts.googleapis.com/css?family=Inter);

* {
 font-family: "Inter", "Helvetica","Arial",sans-serif !important;
}

body {
  background-color: #f7f8fc;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiPopover-root {
  overflow: hidden !important;
}

.MuiTabs-indicator {
  height: 3px !important;
}

.MuiTabs-flexContainer {
  .MuiButtonBase-root {
    color: #4f79d0 !important;
    font-size: 13px !important;
    font-weight: 700 !important;
  }
}

.MuiBox-root:has(.MuiTabs-flexContainer) {
  border-color: transparent !important;
}

input[type='number'] {
  -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
